import React, { Component } from 'react';
import { array, arrayOf, bool, func, number, object, shape, string } from 'prop-types';
import pickBy from 'lodash/pickBy';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';


import appSettings from '../../../config/settings';
import { useConfiguration } from '../../../context/configurationContext';
import { useRouteConfiguration } from '../../../context/routeConfigurationContext';

import { FormattedMessage, intlShape, useIntl } from '../../../util/reactIntl';
import { isMainSearchTypeKeywords, isOriginInUse } from '../../../util/search';
import { post } from '../../../util/api';
import * as validators from '../../../util/validators';
import { parse, stringify } from '../../../util/urlHelpers';
import { createResourceLocatorString, matchPathname, pathByRouteName } from '../../../util/routes';
import { propTypes } from '../../../util/types';
import { getDefaultTimeZoneOnBrowser, getStartOf, timeOfDayFromTimeZoneToLocal } from '../../../util/dates';

import {
  Button,
  LimitedAccessBanner,
  LinkedLogo,
  Form,
  Modal,
  FieldTextInput,
  FieldDateRangeInput,
  FieldSelect,
  FieldNumberInputWithoutNegative,
  ModalMissingInformation,
  H3,
  NamedLink,
} from '../../../components';

import MenuIcon from './MenuIcon';
import SearchIcon from './SearchIcon';
import TopbarSearchForm from './TopbarSearchForm/TopbarSearchForm';
import TopbarMobileMenu from './TopbarMobileMenu/TopbarMobileMenu';
import TopbarDesktop from './TopbarDesktop/TopbarDesktop';

import css from './Topbar.module.css';
import FieldInternationalPhoneNumber from '../../../components/FieldInternationalPhoneNumber/FieldInternationalPhoneNumber';

const MAX_MOBILE_SCREEN_WIDTH = 1024;

const redirectToURLWithModalState = (props, modalStateParam) => {
  const { history, location } = props;
  const { pathname, search, state } = location;
  const searchString = `?${stringify({ [modalStateParam]: 'open', ...parse(search) })}`;
  history.push(`${pathname}${searchString}`, state);
};

const redirectToURLWithoutModalState = (props, modalStateParam) => {
  const { history, location } = props;
  const { pathname, search, state } = location;
  const queryParams = pickBy(parse(search), (v, k) => {
    return k !== modalStateParam;
  });
  const stringified = stringify(queryParams);
  const searchString = stringified ? `?${stringified}` : '';
  history.push(`${pathname}${searchString}`, state);
};

const isPrimary = o => o.group === 'primary';
const isSecondary = o => o.group === 'secondary';
const compareGroups = (a, b) => {
  const isAHigherGroupThanB = isPrimary(a) && isSecondary(b);
  const isALesserGroupThanB = isSecondary(a) && isPrimary(b);
  // Note: sort order is stable in JS
  return isAHigherGroupThanB ? -1 : isALesserGroupThanB ? 1 : 0;
};
// Returns links in order where primary links are returned first
const sortCustomLinks = customLinks => {
  const links = Array.isArray(customLinks) ? customLinks : [];
  return links.sort(compareGroups);
};

// Resolves in-app links against route configuration
const getResolvedCustomLinks = (customLinks, routeConfiguration) => {
  const links = Array.isArray(customLinks) ? customLinks : [];
  return links.map(linkConfig => {
    const { type, href } = linkConfig;
    const isInternalLink = type === 'internal' || href.charAt(0) === '/';
    if (isInternalLink) {
      // Internal link
      const testURL = new URL('http://my.marketplace.com' + href);
      const matchedRoutes = matchPathname(testURL.pathname, routeConfiguration);
      if (matchedRoutes.length > 0) {
        const found = matchedRoutes[0];
        const to = { search: testURL.search, hash: testURL.hash };
        return {
          ...linkConfig,
          route: {
            name: found.route?.name,
            params: found.params,
            to,
          },
        };
      }
    }
    return linkConfig;
  });
};

const isCMSPage = found =>
  found.route?.name === 'CMSPage' ? `CMSPage:${found.params?.pageId}` : null;
const isInboxPage = found =>
  found.route?.name === 'InboxPage' ? `InboxPage:${found.params?.tab}` : null;
// Find the name of the current route/pathname.
// It's used as handle for currentPage check.
const getResolvedCurrentPage = (location, routeConfiguration) => {
  const matchedRoutes = matchPathname(location.pathname, routeConfiguration);
  if (matchedRoutes.length > 0) {
    const found = matchedRoutes[0];
    const cmsPageName = isCMSPage(found);
    const inboxPageName = isInboxPage(found);
    return cmsPageName ? cmsPageName : inboxPageName ? inboxPageName : `${found.route?.name}`;
  }
};

const GenericError = props => {
  const { show } = props;
  const classes = classNames(css.genericError, {
    [css.genericErrorVisible]: show,
  });
  return (
    <div className={classes}>
      <div className={css.genericErrorContent}>
        <p className={css.genericErrorText}>
          <FormattedMessage id="Topbar.genericError" />
        </p>
      </div>
    </div>
  );
};

GenericError.propTypes = {
  show: bool.isRequired,
};

const rvTypes = [
  { label: 'Campervan', option: 'Campervan' },
  { label: 'Citycamper', option: 'Citycamper' },
  { label: 'Motorhome', option: 'Motorhome' },
  // { label: 'Fryd', option: 'Fryd' },
  // { label: 'Helenium', option: 'Helenium' },
  // { label: 'Ruby', option: 'Ruby' },
  // { label: 'Rollerteam', option: 'Rollerteam' },
  // { label: 'Motorhome', option: 'Motorhome' },
  // { label: 'Citycamper', option: 'Citycamper' },
  // { label: 'Giottiline', option: 'Giottiline' }
];

const locationPoints = [
  { label: 'Oslo', option: 'Oslo' },
  { label: 'Stockholm', option: 'Stockholm' },
  { label: 'Copenhagen', option: 'Copenhagen' },
  { label: 'Gothenburg', option: 'Gothenburg' },
  { label: 'Lofoten', option: 'Lofoten' },
  { label: 'Aalborg', option: 'Aalborg' },
  { label: 'Tromsø', option: 'Tromsø' },
  { label: 'Malmø', option: 'Malmø' },
  { label: 'Trondheim', option: 'Trondheim' },
  { label: 'Bergen', option: 'Bergen' },
  { label: 'Stavanger', option: 'Stavanger' },
  { label: 'Aarhus', option: 'Aarhus' },
]

class TopbarComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formSubmitted: false,
    };
    this.handleMobileMenuOpen = this.handleMobileMenuOpen.bind(this);
    this.handleMobileMenuClose = this.handleMobileMenuClose.bind(this);
    this.handleMobileSearchOpen = this.handleMobileSearchOpen.bind(this);
    this.handleMobileSearchClose = this.handleMobileSearchClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  handleMobileMenuOpen() {
    redirectToURLWithModalState(this.props, 'mobilemenu');
  }

  handleMobileMenuClose() {
    redirectToURLWithoutModalState(this.props, 'mobilemenu');
  }

  handleMobileSearchOpen() {
    redirectToURLWithModalState(this.props, 'mobilesearch');
  }

  handleMobileSearchClose() {
    redirectToURLWithoutModalState(this.props, 'mobilesearch');
  }

  handleSubmit(values) {
    const { currentSearchParams } = this.props;
    const { history, config, routeConfiguration } = this.props;

    const topbarSearchParams = () => {
      if (isMainSearchTypeKeywords(config)) {
        return { keywords: values?.keywords };
      }
      // topbar search defaults to 'location' search
      const { search, selectedPlace } = values?.location;
      const { origin, bounds } = selectedPlace;
      const originMaybe = isOriginInUse(config) ? { origin } : {};

      return {
        ...originMaybe,
        address: search,
        bounds,
      };
    };
    const searchParams = {
      ...currentSearchParams,
      ...topbarSearchParams(),
    };
    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, searchParams));
  }

  handleLogout() {
    const { onLogout, history, routeConfiguration } = this.props;
    onLogout().then(() => {
      const path = pathByRouteName('LandingPage', routeConfiguration);

      // In production we ensure that data is really lost,
      // but in development mode we use stored values for debugging
      if (appSettings.dev) {
        history.push(path);
      } else if (typeof window !== 'undefined') {
        window.location = path;
      }

      console.log('logged out'); // eslint-disable-line
    });
  }

  render() {
    const {
      className,
      rootClassName,
      desktopClassName,
      mobileRootClassName,
      mobileClassName,
      isAuthenticated,
      authScopes,
      authInProgress,
      currentUser,
      currentUserHasListings,
      currentUserHasOrders,
      currentPage,
      notificationCount,
      intl,
      location,
      onManageDisableScrolling,
      onResendVerificationEmail,
      sendVerificationEmailInProgress,
      sendVerificationEmailError,
      showGenericError,
      config,
      routeConfiguration,
      formModalOpen,
      setFormModalOpen,
    } = this.props;

    const onSubmit = values => {
      const emailContent = `
    <h2>${intl.formatMessage({ id: 'LandingContactForm.EmailSubject' })}</h2>
    <p>${intl.formatMessage({ id: 'LandingContactForm.EmailBodyDetails' })}</p>
    <p><strong>${intl.formatMessage({ id: 'LandingContactForm.Name' })}:</strong> ${values.firstName} ${values.lastName}</p>
    <p><strong>${intl.formatMessage({ id: 'LandingContactForm.Email' })}:</strong> ${values.email.trim()}</p>
    <p><strong>${intl.formatMessage({ id: 'LandingContactForm.Phone' })}:</strong> ${values.phoneNumber}</p>
    <p><strong>${intl.formatMessage({ id: 'LandingContactForm.PickUpLocation' })}:</strong> ${values.pickUp}</p>
    <p><strong>${intl.formatMessage({ id: 'LandingContactForm.DropOffLocation' })}:</strong> ${values.dropOff}</p>
    <p><strong>${intl.formatMessage({ id: 'LandingContactForm.BookingDates' })}:</strong> 
      ${values.bookingDates.startDate} - ${values.bookingDates.endDate}</p>
    <p><strong>${intl.formatMessage({ id: 'LandingContactForm.NumberOfPassengers' })}:</strong> ${values.numberOfPassengers}</p>
    <p><strong>${intl.formatMessage({ id: 'LandingContactForm.RvType' })}:</strong> ${values.rvType}</p>
  `;

      const requestOperatorOptions = {
        subject: intl.formatMessage({ id: 'LandingContactForm.EmailSubject' }),
        text: emailContent,
      };

      post('/api/sendgrid-operator-email', requestOperatorOptions)
        .then(response => {
          setFormModalOpen(false);
          this.setState({ formSubmitted: true });
        })
        .catch(e => {
        });
    };


    const { mobilemenu, mobilesearch, keywords, address, origin, bounds } = parse(location.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });

    // Custom links are sorted so that group="primary" are always at the beginning of the list.
    const sortedCustomLinks = sortCustomLinks(config.topbar?.customLinks);
    const customLinks = getResolvedCustomLinks(sortedCustomLinks, routeConfiguration);
    const resolvedCurrentPage = currentPage || getResolvedCurrentPage(location, routeConfiguration);

    const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

    const hasMatchMedia = typeof window !== 'undefined' && window?.matchMedia;
    const isMobileLayout = hasMatchMedia
      ? window.matchMedia(`(max-width: ${MAX_MOBILE_SCREEN_WIDTH}px)`)?.matches
      : true;
    const isMobileMenuOpen = isMobileLayout && mobilemenu === 'open';
    const isMobileSearchOpen = isMobileLayout && mobilesearch === 'open';

    const mobileMenu = (
      <TopbarMobileMenu
        isAuthenticated={isAuthenticated}
        currentUserHasListings={currentUserHasListings}
        currentUser={currentUser}
        onLogout={this.handleLogout}
        notificationCount={notificationCount}
        currentPage={resolvedCurrentPage}
        customLinks={customLinks}
      />
    );

    const topbarSearcInitialValues = () => {
      if (isMainSearchTypeKeywords(config)) {
        return { keywords };
      }

      // Only render current search if full place object is available in the URL params
      const locationFieldsPresent = isOriginInUse(config)
        ? address && origin && bounds
        : address && bounds;
      return {
        location: locationFieldsPresent
          ? {
            search: address,
            selectedPlace: { address, origin, bounds },
          }
          : null,
      };
    };
    const initialSearchFormValues = topbarSearcInitialValues();

    const classes = classNames(rootClassName || css.root, className);

    return (
      <div className={classes}>
        <LimitedAccessBanner
          isAuthenticated={isAuthenticated}
          authScopes={authScopes}
          currentUser={currentUser}
          onLogout={this.handleLogout}
          currentPage={resolvedCurrentPage}
        />
        <div className={classNames(mobileRootClassName || css.container, mobileClassName)}>
          <Button
            rootClassName={css.menu}
            onClick={this.handleMobileMenuOpen}
            title={intl.formatMessage({ id: 'Topbar.menuIcon' })}
          >
            <MenuIcon className={css.menuIcon} />
            {notificationDot}
          </Button>
          <LinkedLogo
            layout={'mobile'}
            alt={intl.formatMessage({ id: 'Topbar.logoIcon' })}
            linkToExternalSite={config?.topbar?.logoLink}
          />
          <Button
            rootClassName={css.searchMenu}
            onClick={this.handleMobileSearchOpen}
            title={intl.formatMessage({ id: 'Topbar.searchIcon' })}
          >
            <SearchIcon className={css.searchMenuIcon} />
          </Button>
        </div>
        <div className={css.desktop}>
          <TopbarDesktop
            className={desktopClassName}
            currentUserHasListings={currentUserHasListings}
            currentUser={currentUser}
            currentPage={resolvedCurrentPage}
            initialSearchFormValues={initialSearchFormValues}
            intl={intl}
            isAuthenticated={isAuthenticated}
            notificationCount={notificationCount}
            onLogout={this.handleLogout}
            onSearchSubmit={this.handleSubmit}
            config={config}
            customLinks={customLinks}
            setFormModalOpen={setFormModalOpen}
          />
        </div>
        <Modal
          id="TopbarMobileMenu"
          containerClassName={css.modalContainer}
          isOpen={isMobileMenuOpen}
          onClose={this.handleMobileMenuClose}
          usePortal
          onManageDisableScrolling={onManageDisableScrolling}
        >
          {authInProgress ? null : mobileMenu}
        </Modal>
        <Modal
          id="TopbarMobileSearch"
          containerClassName={css.modalContainerSearchForm}
          isOpen={isMobileSearchOpen}
          onClose={this.handleMobileSearchClose}
          usePortal
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <div className={css.searchContainer}>
            <TopbarSearchForm
              onSubmit={this.handleSubmit}
              initialValues={initialSearchFormValues}
              isMobile
              appConfig={config}
            />
            <p className={css.mobileHelp}>
              <FormattedMessage id="Topbar.mobileSearchHelp" />
            </p>
          </div>
        </Modal>
        <ModalMissingInformation
          id="MissingInformationReminder"
          containerClassName={css.missingInformationModal}
          currentUser={currentUser}
          currentUserHasListings={currentUserHasListings}
          currentUserHasOrders={currentUserHasOrders}
          location={location}
          onManageDisableScrolling={onManageDisableScrolling}
          onResendVerificationEmail={onResendVerificationEmail}
          sendVerificationEmailInProgress={sendVerificationEmailInProgress}
          sendVerificationEmailError={sendVerificationEmailError}
        />

        <Modal
          id="LandingPage.form"
          containerClassName={css.container}
          isOpen={formModalOpen}
          onClose={() => setFormModalOpen(false)}
          // usePortal
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <FinalForm
            onSubmit={onSubmit}
            render={fieldRenderProps => {
              const {
                autoFocus,
                disabled,
                ready,
                formId,
                form,
                invalid,
                updated,
                updateInProgress,
                values,
                handleSubmit: handleContactFormSubmit,
                onChange,
                pristine,
                submitting,
              } = fieldRenderProps;

              const submitReady = (updated && pristine) || ready;
              const submitInProgress = updateInProgress;
              const submitDisabled = invalid || disabled || submitInProgress;

              const formatDate = date => new Intl.DateTimeFormat('en-US').format(date);
              const startDatePlaceholderText = formatDate(new Date());
              const endDatePlaceholderText = formatDate(getStartOf(new Date(), 'day', 'Etc/UTC', 1, 'days'));
              const defaultTimeZone = () =>
                typeof window !== 'undefined' ? getDefaultTimeZoneOnBrowser() : 'Etc/UTC';

              const minValue = min => value =>
                value && value < min ?
                  intl.formatMessage({ id: 'LandingContactForm.notValid' })
                  : undefined;

              const validateDateRange = value => {
                const { startDate, endDate } = value || {};

                if (!startDate) {
                  return intl.formatMessage({ id: 'LandingContactForm.startDateRequired' });
                }
                if (!endDate) {
                  return intl.formatMessage({ id: 'LandingContactForm.endDateRequired' });
                }
                return undefined;
              };

              const emailRequiredMessage = intl.formatMessage({
                id: 'LandingContactForm.emailRequired',
              });
              const emailRequired = validators.required(emailRequiredMessage);
              const emailInvalidMessage = intl.formatMessage({ id: 'LandingContactForm.emailInvalid' });
              const emailValid = validators.emailFormatValid(emailInvalidMessage);

              const emailMatchErrorMessage = intl.formatMessage({ id: 'LandingContactForm.emailMatchError' });

              const validateEmailMatch = (value) => {
                if (value !== values.email) {
                  return emailMatchErrorMessage;
                }
                return undefined;
              };

              return (
                <Form
                  onSubmit={handleContactFormSubmit}
                  className={css.form}
                >
                  <div className={css.title}>
                    <FormattedMessage id={'LandingContactForm.title'} />
                  </div>

                  <section className={css.section}>
                    <div>
                      <FormattedMessage id={'LandingContactForm.travelDetails'} />
                    </div>
                    <div className={css.column}>
                      <div className={css.row}>
                        <FieldSelect
                          className={css.input}
                          name="pickUp"
                          id={formId ? `${formId}.pickUp` : 'pickUp'}
                          label={intl.formatMessage({ id: 'LandingContactForm.pickUp' })}
                          validate={validators.required('Required')}

                        >
                          <option disabled value="">
                            {intl.formatMessage({ id: 'LandingContactForm.pickOption' })}
                          </option>
                          {locationPoints.map(location => {
                            return (
                              <option key={location.option} value={location.option}>
                                {location.label}
                              </option>
                            );
                          })}
                        </FieldSelect>
                        <FieldSelect
                          className={css.input}
                          name="dropOff"
                          id={formId ? `${formId}.dropOff` : 'dropOff'}
                          label={intl.formatMessage({ id: 'LandingContactForm.dropOff' })}
                          validate={validators.required('Required')}
                        >
                          <option disabled value="">
                            {intl.formatMessage({ id: 'LandingContactForm.pickOption' })}
                          </option>
                          {locationPoints.map(location => {
                            return (
                              <option key={location.option} value={location.option}>
                                {location.label}
                              </option>
                            );
                          })}
                        </FieldSelect>
                      </div>
                      <FieldDateRangeInput
                        name="bookingDates"
                        dateRangeInputClassName={css.dateRangeInput}
                        labelsClassName={css.labelsClassName}
                        isDaily={false}
                        startDateId="LandingContactForm.bookingStartDate"
                        startDateLabel={intl.formatMessage({ id: 'LandingContactForm.startDate' })}
                        startDatePlaceholderText={startDatePlaceholderText}
                        endDateId="LandingContactForm.bookingEndDate"
                        endDateLabel={intl.formatMessage({ id: 'LandingContactForm.endDate' })}
                        endDatePlaceholderText={endDatePlaceholderText}
                        validate={validateDateRange}
                        format={v => {
                          const { startDate, endDate } = v || {};
                          // Format the Final Form field's value for the DateRangeInput
                          // DateRangeInput operates on local time zone, but the form uses listing's time zone
                          const formattedStart = startDate
                            ? timeOfDayFromTimeZoneToLocal(startDate, defaultTimeZone())
                            : startDate;
                          const formattedEnd = endDate
                            ? timeOfDayFromTimeZoneToLocal(endDate, defaultTimeZone())
                            : endDate;
                          return v ? { startDate: formattedStart, endDate: formattedEnd } : v;
                        }}
                        isBlockedBetween={() => {
                          return false;
                        }}
                        isDayBlocked={() => () => {
                          return false;
                        }}
                        isOutsideRange={() => () => {
                          return false;
                        }}
                      />
                      <div className={css.row}>
                        <FieldNumberInputWithoutNegative
                          className={css.input}
                          name="numberOfPassengers"
                          id={formId ? `${formId}.numberOfPassengers` : 'numberOfPassengers'}
                          label={intl.formatMessage({ id: 'LandingContactForm.numberOfPassengers' })}
                          validate={validators.composeValidators(validators.required('Required'), minValue(1))}
                        />
                        <FieldSelect
                          className={css.input}
                          name="rvType"
                          id={formId ? `${formId}.rvType` : 'rvType'}
                          label={intl.formatMessage({ id: 'LandingContactForm.rvType' })}
                        >
                          <option disabled value="">
                            {intl.formatMessage({ id: 'LandingContactForm.pickOption' })}
                          </option>
                          {rvTypes.map(rv => {
                            return (
                              <option key={rv.option} value={rv.option}>
                                {rv.label}
                              </option>
                            );
                          })}
                        </FieldSelect>
                      </div>

                    </div>
                  </section>
                  <div className={css.divier} />
                  <section className={css.section}>
                    <div>
                      <FormattedMessage id={'LandingContactForm.personalDetails'} />
                    </div>
                    <div className={css.column}>
                      <div className={css.row}>
                        <FieldTextInput
                          className={css.input}
                          type="text"
                          id={formId ? `${formId}.firstName` : 'firstName'}
                          name="firstName"
                          autoComplete="given-name"
                          label={intl.formatMessage({ id: 'LandingContactForm.firstNameLabel' })}
                          validate={validators.required(intl.formatMessage({ id: 'LandingContactForm.firstNameRequired' })
                            // placeholder={intl.formatMessage({ id: 'LandingContactForm.firstNamePlaceholder' })}
                            // initialValue={firstName}
                          )}
                        />
                        <FieldTextInput
                          className={css.input}
                          type="text"
                          id={formId ? `${formId}.lastName` : 'lastName'}
                          name="lastName"
                          autoComplete="family-name"
                          label={intl.formatMessage({ id: 'LandingContactForm.lastNameLabel' })}
                          validate={validators.required(intl.formatMessage({ id: 'LandingContactForm.lastNameRequired' }))}
                        // placeholder={intl.formatMessage({ id: 'ConfirmSignupForm.lastNamePlaceholder' })}
                        // initialValue={lastName}
                        />
                      </div>
                      <div className={css.row}>

                        <FieldInternationalPhoneNumber
                          type="text"
                          id={formId ? `${formId}.phoneNumber` : 'phoneNumber'}
                          name="phoneNumber"
                          label={intl.formatMessage({ id: 'LandingContactForm.phoneLabel' })}
                          defaultCountry="NO"
                          withCountryCallingCode
                          countryCallingCodeEditable={false}
                          international
                          className={css.phoneField}
                          validate={validators.required("Phone number is required")} />

                        {/* <div className={css.input} /> */}
                      </div>

                      <div className={css.row}>
                        <FieldTextInput
                          className={css.input}
                          type="email"
                          name="email"
                          id={formId ? `${formId}.email` : 'email'}
                          label={intl.formatMessage({ id: 'LandingContactForm.emailLabel' })}
                          // placeholder={intl.formatMessage({ id: 'ContactDetailsForm.emailPlaceholder' })}
                          validate={validators.composeValidators(emailRequired, emailValid)}
                        />
                        {/* <div className={css.input} /> */}
                      </div>
                      <div className={css.row}>
                        <FieldTextInput
                          className={css.input}
                          type="email"
                          name="confirmEmail"
                          id={formId ? `${formId}.confirmEmail` : 'confirmEmail'}
                          label={intl.formatMessage({ id: 'LandingContactForm.confirmEmailLabel' })}
                          // placeholder={intl.formatMessage({ id: 'ContactDetailsForm.emailPlaceholder' })}
                          validate={validators.composeValidators(emailRequired, emailValid, validateEmailMatch)}
                        />
                        {/* <div className={css.input} /> */}
                      </div>
                    </div>
                  </section>

                  <Button
                    type="submit"
                    disabled={submitDisabled}
                    className={css.button}
                  >
                    <FormattedMessage id="LandingContactForm.submit" />
                  </Button>
                </Form>
              );
            }}
          />
        </Modal>
        <Modal
          id="LandingPage.formSubmitted"
          isOpen={this.state.formSubmitted}
          onClose={() => this.setState({ formSubmitted: false })}
          // usePortal
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <div className={css.submitted}>
            <H3>
              <FormattedMessage id="LandingContactFormSubmitted.title" />
            </H3>

            <p className={css.info}>
              <FormattedMessage id="LandingContactFormSubmitted.info1" />
            </p>
            <p className={css.info}>
              <FormattedMessage id="LandingContactFormSubmitted.info2" />
            </p>

            <NamedLink name="SearchPage">
              <Button
                className={css.button}
                onClick={() => {
                  this.setState({ formSubmitted: false })
                }}>
                <FormattedMessage id="LandingContactFormSubmitted.submit" />
              </Button>
            </NamedLink>
          </div>
        </Modal>

        <GenericError show={showGenericError} />
      </div>
    );
  }
}

TopbarComponent.defaultProps = {
  className: null,
  rootClassName: null,
  desktopClassName: null,
  mobileRootClassName: null,
  mobileClassName: null,
  notificationCount: 0,
  currentUser: null,
  currentUserHasOrders: null,
  currentPage: null,
  sendVerificationEmailError: null,
  authScopes: [],
};

TopbarComponent.propTypes = {
  className: string,
  rootClassName: string,
  desktopClassName: string,
  mobileRootClassName: string,
  mobileClassName: string,
  isAuthenticated: bool.isRequired,
  authScopes: array,
  authInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentUserHasListings: bool.isRequired,
  currentUserHasOrders: bool,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onResendVerificationEmail: func.isRequired,
  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  showGenericError: bool.isRequired,

  // These are passed from Page to keep Topbar rendering aware of location changes
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from useConfiguration
  config: object.isRequired,

  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,
};

const Topbar = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  return (
    <TopbarComponent
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      {...props}
    />
  );
};

export default Topbar;
